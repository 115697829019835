import { lazy, Suspense, useEffect } from 'react'

/// Components
import Index from './jsx'
import { connect, useDispatch } from 'react-redux'
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
// action
import { checkAutoLogin } from './services/AuthService'
import { isAuthenticated } from './store/selectors/AuthSelectors'
import { Logout } from './store/actions/AuthActions'
/// Style
// import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import './css/style.css'
import Home from './jsx/components/Dashboard/Home'
import { checkProfile, resetLocalStorage } from './store/actions/PostActions'
import { updateUser } from './store/actions/UserActions'
import ConfirmForgotPasswordLogin from './jsx/pages/ConfirmForgotPasswordLogin'

const SignUp = lazy(() => import('./jsx/pages/Registration'))
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'))
const ConfirmForgotPassword = lazy(() =>
  import('./jsx/pages/ConfirmForgotPassword'),
)
const Verification = lazy(() => import('./jsx/pages/Verification'))

const Login = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import('./jsx/pages/Login')), 500)
  })
})

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()

    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}

const noAuthorized = [
  {
    url: '/login',
    component: <Login />,
  },
  {
    url: '/verification',
    component: <Verification />,
  },
  {
    url: '/register',
    component: <SignUp />,
  },
  {
    url: '/forgot-password',
    component: <ForgotPassword />,
  },
  {
    url: '/confirm-forget-password',
    component: <ConfirmForgotPasswordLogin />,
  },
]

function App(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // useEffect(() => {
  // dispatch(checkProfile())
  // }, [])

  let routeblog = (
    <Routes>
      {noAuthorized.map((el, ind) => {
        return <Route key={ind} path={el.url} element={el.component} />
      })}
    </Routes>
  )

  const profile = JSON.parse(localStorage.getItem('profile'))

  let isNoAuthPath = !noAuthorized.some(
    el => el.url === window.location.pathname,
  )
  useEffect(() => {
    if (isNoAuthPath) {
      !!profile === false && dispatch(Logout(navigate))
      dispatch(updateUser(profile))
    }
  }, [profile])

  useEffect(() => {
    if (isNoAuthPath) {
      dispatch(resetLocalStorage())
    }
  }, [])

  if (!!profile?.email) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    )
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routeblog}
        </Suspense>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state),
  }
}

//export default connect((mapStateToProps)(App));
export default withRouter(connect(mapStateToProps)(App))
