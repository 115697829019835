import {
  createPost,
  formatPosts,
  getPosts,
  deletePost,
  updatePost,
  getProfile,
  getSymbolsForMarketData,
} from '../../services/PostsService'
import { setLoading } from '../actions/LoadingActions'
import {
  CONFIRMED_CREATE_POST_ACTION,
  CONFIRMED_DELETE_POST_ACTION,
  CONFIRMED_EDIT_POST_ACTION,
  CONFIRMED_GET_POSTS,
} from '../type/PostTypes'
import { setSubscribeModalStatus } from './ModalsActions'

export function deletePostAction(postId, history) {
  return (dispatch, getState) => {
    deletePost(postId).then(response => {
      dispatch(confirmedDeletePostAction(postId))
      history.push('/postpage')
    })
  }
}

export function getProfileAction(navigate) {
  return (dispatch, getState) => {
    getProfile()
      .then(response => {
        localStorage.setItem('profile', JSON.stringify(response.data))
        navigate('/dashboard')
      })
      .catch(error => {
        localStorage.removeItem('profile')
        navigate('/login')
      })
  }
}

export function checkProfile() {
  return (dispatch, getState) => {
    dispatch(setLoading(true))
    getProfile()
      .then(response => {
        localStorage.setItem('profile', JSON.stringify(response.data))
        dispatch(setLoading(false))
        dispatch(setSubscribeModalStatus(true))
      })
      .catch(error => {
        localStorage.removeItem('userEmailVerified')
        dispatch(setLoading(false))
      })
  }
}
export function resetLocalStorage() {
  console.log('resetLocalStorage')
  return (dispatch, getState) => {
    dispatch(setLoading(true))
    getProfile()
      .then(response => {
        localStorage.setItem('profile', JSON.stringify(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        localStorage.removeItem('userEmailVerified')
        dispatch(setLoading(false))
      })
  }
}

export function confirmedDeletePostAction(postId) {
  return {
    type: CONFIRMED_DELETE_POST_ACTION,
    payload: postId,
  }
}

export function createPostAction(postData, history) {
  return (dispatch, getState) => {
    createPost(postData).then(response => {
      const singlePost = {
        ...postData,
        id: response.data.name,
      }
      dispatch(confirmedCreatePostAction(singlePost))
      history.push('/postpage')
    })
  }
}

export function getPostsAction() {
  return (dispatch, getState) => {
    getPosts().then(response => {
      let posts = formatPosts(response.data)
      dispatch(confirmedGetPostsAction(posts))
    })
  }
}

export function confirmedCreatePostAction(singlePost) {
  return {
    type: CONFIRMED_CREATE_POST_ACTION,
    payload: singlePost,
  }
}

export function confirmedGetPostsAction(posts) {
  return {
    type: CONFIRMED_GET_POSTS,
    payload: posts,
  }
}

export function confirmedUpdatePostAction(post) {
  return {
    type: CONFIRMED_EDIT_POST_ACTION,
    payload: post,
  }
}

export function updatePostAction(post, history) {
  return (dispatch, getState) => {
    updatePost(post, post.id).then(response => {
      dispatch(confirmedUpdatePostAction(post))
      history.push('/postpage')
    })
  }
}
