import React, { useState, useEffect, useMemo } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Button, Modal, Container } from 'react-bootstrap'
import { getSymbols } from '../../../store/selectors/SymbolsSelectors'
import LogoutPage from './Logout'
import { SelectSymbol } from './SelectSymbol'
import { useTranslation } from 'react-i18next'
import {
  getAllSymbols,
  setActiveSymbols,
} from '../../../store/actions/SymbolsActions'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { permissionsCheck } from '../../../utils/permission/permission'
import { IconGear } from '../../../icons/IconGear'
import { getSubscribeModalStatus } from '../../../store/selectors/ModalsSelectors'
import { setSubscribeModalStatus } from '../../../store/actions/ModalsActions'
import { ROLES } from '../../../types/role.Types'
import { checkProfile } from '../../../store/actions/PostActions'
import { getUser } from '../../../store/selectors/UserSelectors'

const Header = ({ onNote }) => {
  const { t, i18n } = useTranslation()
  let navigate = useNavigate()
  const dispatch = useDispatch()
  const { activeSymbol, symbolsData } = useSelector(getSymbols)
  const user = useSelector(getUser)
  const { statusSubscribeModal } = useSelector(getSubscribeModalStatus)
  const [rightSelect, setRightSelect] = useState('Eng')
  const [email, setEmail] = useState('')
  const [userData, setUserData] = useState({})
  //For fix header
  const [headerFix, setheaderFix] = useState(false)
  const [gridInsideModal, setGridInsideModal] = useState(false)
  const profile = JSON.parse(localStorage.getItem('profile') ?? {})

  const handleChangeSelect = e => {
    let targetValue = e
    symbolsData.some(el => {
      if (el.name === targetValue) {
        return dispatch(setActiveSymbols(el))
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setheaderFix(window.scrollY > 50)
    })
  }, [])

  const StripePricingTable = () => {
    useEffect(() => {
      const script = document.createElement('script')
      script.src = 'https://js.stripe.com/v3/pricing-table.js'
      script.async = true
      document.body.appendChild(script)

      // return () => {
      //   document.body.removeChild(script)
      // }
    }, [])

    return React.createElement('stripe-pricing-table', {
      'pricing-table-id': process.env.REACT_APP_STRIPE_PRICING_TABLE,
      'publishable-key': process.env.REACT_APP_STRIPE_PUB_KEY,
      'customer-email': `${profile.email}`,
    })
  }

  useEffect(() => {
    dispatch(getAllSymbols())
  }, [])

  const openSubscribe = () => {
    dispatch(checkProfile())
  }

  const userName = useMemo(() => {
    return <span>{`${user?.firstName} ${user?.lastName}`}</span>
  }, [user?.firstName, user?.lastName])

  return (
    <div className={`header ${headerFix ? 'is-fixed' : ''}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              {/* <div
                className="dashboard_bar"
                style={{ textTransform: 'capitalize' }}
              >
                {finalName.join(' ').length === 0
                  ? 'Dashboard'
                  : finalName.join(' ') === 'dashboard dark'
                  ? 'Dashboard'
                  : finalName.join(' ')}
              </div> */}
              <SelectSymbol
                value={activeSymbol?.name}
                name={'name'}
                data={symbolsData}
                onChange={handleChangeSelect}
              />
            </div>
            <div className="navbar-nav header-right">
              {!permissionsCheck('customer') && !permissionsCheck('hasSubscription') && (
                <div className="nav-item d-flex align-items-center d-md-flex d-sm-none d-mobile-none">
                  <div className="text-center subscribe-btn-container">
                    <button
                      className="btn btn-primary w-75"
                      onClick={openSubscribe}
                    >
                      {t('nav.subscribe')}
                    </button>
                  </div>
                </div>
              )}

              {/* <div className="search-coundry d-flex align-items-center">
                <img src={United} alt="" className='mx-2'/>						
                <Dropdown className='sidebar-dropdown me-2 mt-2'>
                  <Dropdown.Toggle as='div' className='i-false sidebar-select'>{rightSelect} <i className="fa-solid fa-angle-down ms-2" /></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={()=>setRightSelect("Eng")}>Eng</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>		 */}
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                >
                  {userName}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="dropdown-menu dropdown-menu-end"
                >
                  <Link to="/app-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary me-1"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ms-2">{t('nav.profile')}</span>
                  </Link>
                  {permissionsCheck('hasSubscription') && (
                    <button
                      className="dropdown-item ai-icon"
                      onClick={() =>
                        window.location.replace(
                          process.env
                            .REACT_APP_RECOVERY_MANAGE_SUBSCRIBTION_URL,
                        )
                      }
                    >
                      <IconGear />
                      <span className="ms-2">
                        {t('menu.manageSubscription')}
                      </span>
                    </button>
                  )}
                  <LogoutPage />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </nav>
      </div>
      <Modal className="fade" show={statusSubscribeModal}>
        <Modal.Header>
          <Modal.Title>{t('nav.subscribe')}</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              dispatch(setSubscribeModalStatus(false))
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <StripePricingTable></StripePricingTable>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Header
