import axios from 'axios'
import { store } from '../store/store'

const server_url = process.env.REACT_APP_API_BASE_URL

axios.defaults.withCredentials = true
const axiosInstance = axios.create({
  baseURL: server_url,
  withCredentials: true,
})

axiosInstance.interceptors.request.use(config => {
  const state = store.getState()
  const token = state.auth.auth.idToken
  config.params = config.params || {}
  return config
})

axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const errorCode = error.response?.status
    if (errorCode == 401) {
      console.error('ERROR 401')
      window.location.pathname = '/login'
      localStorage.removeItem('profile')
    }

    return Promise.reject(error)
  },
)

export default axiosInstance
