import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import PostsReducer from './reducers/PostsReducer'
import thunk from 'redux-thunk'
import { AuthReducer } from './reducers/AuthReducer'
import todoReducers from './reducers/Reducers'
import LoadingReducer from './reducers/LoadingReducer'
import SymbolsReducer from './reducers/SymbolsReducer'
import ModalsReducer from './reducers/ModalsReducer'
import UserReducer from './reducers/UserReducer'

//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const reducers = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  //form: reduxFormReducer,
  load: LoadingReducer,
  symbols: SymbolsReducer,
  modals: ModalsReducer,
  user: UserReducer,
})

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware))
