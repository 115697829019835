import {
  formatError,
  login,
  logout,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
  verificationConfirm,
} from '../../services/AuthService'
import { setLoading } from '../../store/actions/LoadingActions'
import { getProfileAction } from './PostActions'
import Swal from 'sweetalert2'
import { AUTH_ACTIONS } from '../type/AuthActionsTypes'

export function signupAction(
  email,
  password,
  firstName,
  lastName,
  navigate,
  t,
) {
  return dispatch => {
    signUp(email, password, firstName, lastName)
      .then(response => {
        // saveTokenInLocalStorage(response.data);
        // runLogoutTimer(
        //     dispatch,
        //     response.data.expiresIn * 1000,
        //     //history,
        // );
        // dispatch(confirmedSignupAction(response.data));
        Swal.fire({
          title: t('registration.successRequest'),
          icon: 'success',
          confirmButtonText: t('registration.goIt'),
        }).then(() => {
          navigate('/login')
        })
        //history.push('/dashboard');
      })
      .catch(error => {

        if (error?.response?.status === 400) {
          Swal.fire({
            title: t('registration.invalidRequest'),
            icon: 'error',
            confirmButtonText: t('registration.goIt'),
          })
        } else {
          Swal.fire({
            title: t('registration.contactAdmin'),
            icon: 'error',
            confirmButtonText: t('registration.goIt'),
          })
        }
        // const errorMessage = formatError(error.response.data)
        // dispatch(signupFailedAction(errorMessage))
      })
  }
}

export function Logout(navigate) {
  logout()
    .then(() => {
      localStorage.removeItem('profile')
      navigate('/login')
    })
    .catch(() => {
      localStorage.removeItem('profile')
      navigate('/login')
    })

  return {
    type: AUTH_ACTIONS.LOGOUT_ACTION,
  }
}

export function ForgotPassword(navigate) {
  // logout().then(() => {
  //     localStorage.removeItem('userEmailVerified');
  //     navigate('/login');
  // }).catch(() => {
  //     localStorage.removeItem('userEmailVerified');
  //     navigate('/login');
  // });

  return {
    type: AUTH_ACTIONS.LOGOUT_ACTION,
  }
}

export function verificationAction(identifierType, identifier, code, navigate) {
  return dispatch => {
    verificationConfirm(identifierType, identifier, code)
      .then(response => {
        saveTokenInLocalStorage(response.data)
        runLogoutTimer(dispatch, response.data.expiresIn * 1000, navigate)
        dispatch(loginConfirmedAction(response.data))
        //return response.data;
        //return 'success';
        //history.push('/dashboard');
        navigate('/login')
      })
      .catch(error => {
        console.log('error')

        const errorMessage = formatError(error, navigate, { email: identifier })
        dispatch(loginFailedAction(errorMessage))
      })
  }
}

export function loginAction(email, password, navigate, t) {
  return dispatch => {
    dispatch(setLoading(true))
    login(email, password)
      .then(response => {
        // runLogoutTimer(
        //     dispatch,
        //     response.data.expiresIn * 1000,
        //     navigate,
        // );
        dispatch(getProfileAction(navigate))
        //return response.data;
        //return 'success';
        //history.push('/dashboard');
        dispatch(setLoading(false))
      })
      .catch(error => {
        console.log('error')
        // console.log(process.env)
        // console.log(process.env.REACT_APP_API_BASE_URL)
        // console.log(process.env.BASE_URL)
        const errorMessage = formatError(error, navigate, { email: email }, t)
        dispatch(setLoading(false))
        dispatch(loginFailedAction(errorMessage))
      })
  }
}

export function loginFailedAction(data) {
  return {
    type: AUTH_ACTIONS.LOGIN_FAILED_ACTION,
    payload: data,
  }
}

export function loginConfirmedAction(data) {
  return {
    type: AUTH_ACTIONS.LOGIN_CONFIRMED_ACTION,
    payload: data,
  }
}

export function confirmedSignupAction(payload) {
  return {
    type: AUTH_ACTIONS.SIGNUP_CONFIRMED_ACTION,
    payload,
  }
}

export function signupFailedAction(message) {
  return {
    type: AUTH_ACTIONS.SIGNUP_FAILED_ACTION,
    payload: message,
  }
}

export function loadingToggleAction(status) {
  return {
    type: AUTH_ACTIONS.LOADING_TOGGLE_ACTION,
    payload: status,
  }
}
