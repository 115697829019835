import { USER_DATA } from '../type/UserType'

const profile = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  emailVerified: '',
  createdAt: '',
  role: '',
}

export default function UserReducer(state = profile, action) {
  switch (action.type) {
    case USER_DATA.UPDATE_USER:
      return action.profile
    default:
      return state
  }
}
