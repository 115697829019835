import React, {
  Fragment,
  useMemo,
  useReducer,
  useState,
  useEffect,
} from 'react'
import profile02 from '../../../../images/profile/2.jpg'
import profile03 from '../../../../images/profile/3.jpg'
import profile04 from '../../../../images/profile/4.jpg'
import PageTitle from '../../../layouts/PageTitle'
import { useTranslation } from 'react-i18next'
import {
  getProfile,
  recoveryPasswordRequest,
  updatePassword,
  updateProfile,
} from '../../../../services/PostsService'
import Swal from 'sweetalert2'
import { updateUser } from '../../../../store/actions/UserActions'
import { useDispatch } from 'react-redux'

const galleryBlog = [
  { image: profile03 },
  { image: profile04 },
  { image: profile02 },
  { image: profile04 },
  { image: profile03 },
  { image: profile02 },
]

const initialState = false

const reducer = (state, action) => {
  switch (action.type) {
    case 'sendMessage':
      return { ...state, sendMessage: !state.sendMessage }
    case 'postModal':
      return { ...state, post: !state.post }
    case 'linkModal':
      return { ...state, link: !state.link }
    case 'cameraModal':
      return { ...state, camera: !state.camera }
    case 'replyModal':
      return { ...state, reply: !state.reply }
    default:
      return state
  }
}

const AppProfile = () => {
  const { t, i18n } = useTranslation()
  const onInit = () => {
    //console.log('lightGallery has been initialized');
  }
  const [profile, setProfile] = useState({})
  const [shownBox, setShownBox] = useState('')
  const shownBoxType = ['shown-profile-info', 'shown-password']
  const dispatch = useDispatch()

  useEffect(() => {
    getProfile().then(respons => setProfile(respons.data))
  }, [])

  const options = {
    settings: {
      overlayColor: '#000000',
    },
  }

  //   const [state, dispatch] = useReducer(reducer, initialState)

  const onHandleEditProfile = e => {
    const name = e.target.name
    const value = e.target.value
    setProfile(prevState => {
      return {
        ...prevState,
        [name]: value,
      }
    })
  }

  const onForgetPassword = () => {
    if (profile.email) {
      recoveryPasswordRequest({
        identifierType: 'EMAIL',
        identifier: profile.email,
      })
        .then(() => {
          Swal.fire({
            title: t('profile.forget-password-description'),
            icon: 'success',
            confirmButtonText: t('profile.goIt'),
          })
        })
        .catch(error => {
          console.error(error.response.status)
          let errorCode = error.response.status.toString()
          Swal.fire({
            title: t(`ui.errors.error-boundary.${errorCode}.message`),
            icon: 'error',
            confirmButtonText: t(`profile.goIt`),
          })
        })
    }
  }

  const onHandleSubmitEditPassword = shownBox => {
    updatePassword({
      password: profile.password,
      currentPassword: profile.currentPassword,
    })
      .then(() => {
        Swal.fire({
          title: t('profile.yourPasswordHaveBeenChanged'),
          icon: 'success',
          confirmButtonText: t('profile.goIt'),
        })
      })
      .catch(() => {
        Swal.fire({
          title: t('profile.invalidRequest'),
          icon: 'error',
          confirmButtonText: t('profile.goIt'),
        })
      })
  }

  const onHandleSubmit = shownBox => {
    Swal.fire({
      title: t('profile.enterCurrentPassword'),
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: t('profile.submit'),
      showLoaderOnConfirm: true,
      preConfirm: password => {
        return updateProfile({
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          currentPassword: password,
        })
          .then(response => {
            //   if (!response.ok) {
            // 	throw new Error(response.statusText)
            //   }
            //   return response.json()
          })
          .catch(error => {
            if (error.response.data.code == 'BAD_PASSWORD') {
              Swal.showValidationMessage(t('profile.incorrectPassword'))
            }
            if (error.response.data.code == 'BAD_REQUEST') {
              Swal.showValidationMessage(t('profile.invalidRequest'))
            }
          })
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(updateUser(JSON.parse(localStorage.getItem('profile') ?? '')))
        Swal.fire({
          title: t('profile.yourDetailsHaveBeenChanged'),
          icon: 'success',
          confirmButtonText: t('profile.goIt'),
        })
      }
    })
  }

  const userFields = useMemo(() => {
    return (
      <div className={`col`}>
        <div className="form-group mb-3">
          <label className="form-label">{t('profile.email')}</label>
          <input
            name={'email'}
            type="email"
            placeholder={t('profile.email')}
            className="form-control"
            onChange={onHandleEditProfile}
            value={profile?.email || ''}
          />
        </div>
        <div className="form-group mb-3">
          <label className="form-label">{t('profile.firstName')}</label>
          <input
            name={'firstName'}
            type="text"
            placeholder={t('profile.firstName')}
            className="form-control"
            onChange={onHandleEditProfile}
            value={profile?.firstName || ''}
          />
        </div>
        <div className="form-group mb-3">
          <label className="form-label">{t('profile.lastName')}</label>
          <input
            name={'lastName'}
            type="text"
            placeholder={t('profile.lastName')}
            className="form-control"
            onChange={onHandleEditProfile}
            value={profile?.lastName || ''}
          />
        </div>
      </div>
    )
  }, [profile?.email, profile?.firstName, profile?.lastName, shownBox])

  return (
    <Fragment>
      <PageTitle activeMenu="Profile" motherMenu="App" />
      <div className="row">
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <div className="settings-form row">
                <div className="col">
                  <h4 className="text-primary">{t('profile.myAccount')}</h4>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      onHandleSubmit(shownBox)
                    }}
                  >
                    <div style={{ overflow: 'hidden' }}>{userFields}</div>
                    <div className="form-group mb-3 mt-2">
                      <button
                        className="btn btn-primary w-xs-full w-sm-full w-md-full"
                        type={'submit'}
                      >
                        {t('profile.update')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <div className="settings-form row">
                <div className="col">
                  <h4 className="text-primary">{t('profile.myPassword')}</h4>
                  <form
                    onSubmit={e => {
                      e.preventDefault()
                      onHandleSubmitEditPassword()
                    }}
                  >
                    <div style={{ overflow: 'hidden' }}>
                      <div className="form-group mb-3">
                        <label className="form-label">
                          {t('profile.currentPassword')}
                        </label>
                        <input
                          name={'currentPassword'}
                          type="password"
                          placeholder={t('profile.currentPassword')}
                          className="form-control"
                          onChange={onHandleEditProfile}
                          value={profile?.currentPassword}
                        />
                      </div>
                      <div className={`col`}>
                        <div className="form-group mb-3">
                          <label className="form-label">
                            {t('profile.password')}
                          </label>
                          <input
                            name={'password'}
                            type="password"
                            placeholder={t('profile.password')}
                            className="form-control"
                            onChange={onHandleEditProfile}
                            value={profile?.password}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3 mt-2">
                      <button
                        className="btn btn-primary w-xs-full w-sm-full w-md-full"
                        type={'submit'}
                      >
                        {t('profile.update')}
                      </button>
                      <span className="form-check d-inline-block ms-2 float-end">
                        <button
                          type={'button'}
                          className="nav-link m-auto btn tp-btn-light btn-primary"
                          onClick={onForgetPassword}
                        >
                          {t('profile.forget-password')}
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default AppProfile
