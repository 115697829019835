import axios from 'axios'
import Swal from 'sweetalert2'
import { loginConfirmedAction, Logout } from '../store/actions/AuthActions'
import { Link, useNavigate } from 'react-router-dom'
import axiosInstance from '../services/AxiosInstance'

const server_url = process.env.REACT_APP_API_BASE_URL

export function signUp(email, password, firstName, lastName) {
  //axios call
  const postData = {
    email,
    password,
    firstName,
    lastName,
  }
  return axios.post(`${server_url}/v1/auth/registration`, postData, {
    withCredentials: true,
  })
}

export function verificationRequest(identifierType, identifier) {
  const postData = {
    identifierType,
    identifier,
  }
  return axios.post(`${server_url}/v1/auth/verification-request`, postData, {
    withCredentials: true,
  })
}

export function verificationConfirm(identifierType, identifier, code) {
  const postData = {
    identifierType,
    identifier,
    code,
  }
  return axios.post(`${server_url}/v1/auth/verification-confirm`, postData, {
    withCredentials: true,
  })
}

export function login(email, password) {
  const postData = {
    email,
    password,
  }
  return axios.post(`${server_url}/v1/auth/login`, postData, {
    withCredentials: true,
  })
}

export function logout() {
  return axiosInstance.get(`${server_url}/v1/auth/logout`, {
    withCredentials: true,
  })
}

export function formatError(errorResponse, navigate, data, t) {
  // Swal.fire({
  //     title: 'Your email has not been verified!',
  //     showCancelButton: true,
  //     icon: 'info',
  //     text: 'Go to the verification page?',
  //     confirmButtonText: 'Go it',
  //   }).then((result) => {
  //     /* Read more about isConfirmed, isDenied below */
  //     if (result.isConfirmed) {
  //         navigate('/verification', {state:{email: data.email}})
  //     } else if (result.isDenied) {
  //       Swal.fire('Changes are not saved', '', 'info')
  //     }
  //   });
  switch (errorResponse.response.data.code) {
    case 'USER_EMAIL_NOT_VERIFIED':
      //return 'Email already exists';
      // Swal.fire("Oops", "Email already exists", "error");
      navigate('/verification', { state: { email: data.email } })
      break
    case 'USER_EXISTS':
      //return 'Email not found';
      //    swal("Oops", "Email not found", "error",{ button: "Try Again!",});
      break
    case 'UNAUTHORIZED':
      Swal.fire({
        title: t('login.invalid-credentials'),
        icon: 'error',
        confirmButtonText: t('login.goIt'),
      })
      break
    case 'WRONG_CODE':
      break
    case 'INVALID_URL':
      break
    case 'WRONG_TOKEN':
      break
    case 'EXPIRED_TOKEN':
      break
    case 'NOT_ENOUGH_DATA':
      break

    default:
      return ''
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem('userDetails', tokenDetails)
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    //dispatch(Logout(history));
    dispatch(Logout(navigate))
  }, timer)
}

export function checkAutoLogin(dispatch, navigate) {
  const tokenDetailsString = localStorage.getItem('profile')
  let tokenDetails = ''
  if (!tokenDetailsString) {
    dispatch(Logout(navigate))
    return
  }

  tokenDetails = JSON.parse(tokenDetailsString)
  let expireDate = new Date(tokenDetails.expireDate)
  let todaysDate = new Date()

  if (todaysDate > expireDate) {
    dispatch(Logout(navigate))
    return
  }

  dispatch(loginConfirmedAction(tokenDetails))

  const timer = expireDate.getTime() - todaysDate.getTime()
  runLogoutTimer(dispatch, timer, navigate)
}
