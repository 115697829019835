import Form from 'react-bootstrap/Form';
import { Dropdown, FormControl } from 'react-bootstrap';
import React, { useMemo, useState } from 'react';
import { Col } from "react-bootstrap";

import { MultiSelect } from "react-multi-select-component";
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className='customSelectToggle'
  >
    {children}
  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy, searchValue, setSearchValue, selectCategory,selectCategoryData, selectSubCategory,selectSubCategoryData }, ref) => {
    const [selected, setSelected] = useState([]);
    const optionsCatigories = [
      {value:'Indices', label:'Indices'},
      {value:'Stocks', label:'Stocks'},
      {value:'Forex', label:'Forex'},
      {value:'Commodities', label:'Commodities'}
    ];
    const optionsSubCatigories = [
      {value:'Metals', label:'Metals'},
      {value:'Agriculture', label:'Agriculture'},
      {value:'Energy', label:'Energy'},
    ];
    const firstMultiselect = useMemo(() => {
      return (
        <MultiSelect
          options={optionsCatigories}
          value={selectCategoryData}
          onChange={selectCategory}
          hasSelectAll={false}
          valueRenderer={(selected) => {
            if (!selected.length) {
              return "Category";
            }
        
            return `${selected.length} Selected`
          }}
          labelledBy="Select"
          disableSearch
        />
      )
    }, [selectCategoryData, selected]);
    const secondMultiselect = useMemo(() => {
      return (
        <MultiSelect
          options={optionsSubCatigories}
          value={selectSubCategoryData}
          onChange={selectSubCategory}
          disableSearch
          hasSelectAll={false}
          valueRenderer={(selected) => {
            if (!selected.length) {
              return "Sub-Category";
            }
        
            return `${selected.length} Selected`
          }}
          disabled={!selectCategoryData.find(it => it.value == 'Commodities')}
        />
      )
    }, [selectCategoryData, selectSubCategoryData]);

    return (
      <div
        ref={ref}
        className={className}
        aria-labelledby={labeledBy}
        style={{...style, backgroundColor: '#1e1e1e'}}
      >
        <div className="category-container">
          <div className="first-multiselect multiselect-width">
            {firstMultiselect}
          </div>
          <div className="second-multiselect multiselect-width">
            {secondMultiselect}
          </div>
        </div>
        
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto filterSymbolImput"
          placeholder="Type to filter..."
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
        {children.length > 0 ? (
        <ul className="list-unstyled customSelectMenu">
          {React.Children.toArray(children)}
        </ul>
        ) : <div className='select-no-data'>No symbols found</div>}
      </div>
    );
  },
);

export const SelectSymbol = ({
  onChange = () => {},
  value = '',
  data = [],
  name = '',
  size,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectCategoryData, setSelectCategoryData] = useState([
    // {value:'Indices', label:'Indices'},
    // {value:'Stocks', label:'Stocks'},
    // {value:'Forex', label:'Forex'},
    // {value:'Commodities', label:'Commodities'}
  ]);
  const [selectSubCategoryData, setSubSelectCategoryData] = useState([
    // {value:'Metals', selected: false},
    // {value:'Agriculture', selected: false},
    // {value:'Energy', selected: false},
  ]);

  const selectCategory = (value) => {
    setSelectCategoryData(value)
  }

  const selectSubCategory = (value) => {
    setSubSelectCategoryData(value)
  }

  const handleSelect = (eventKey) => {
    onChange(eventKey);
  };

  return (
    <div>
      <Dropdown onSelect={handleSelect}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {value}
        </Dropdown.Toggle>

        <Dropdown.Menu
          as={CustomMenu}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          selectCategoryData={selectCategoryData}
          selectCategory={selectCategory}
          selectSubCategoryData={selectSubCategoryData}
          selectSubCategory={selectSubCategory}
        >
          {data
          .filter(
            (child) => {
              if (!selectCategoryData.length) {
                return true;
              }

              if (selectCategoryData.find(it => it.value.toLowerCase() == child.category.toLowerCase())?.value === 'Commodities') {
                return !child.subCategory 
                  ? true
                  : selectSubCategoryData.find(it => it.value.toLowerCase() == child.subCategory?.toLowerCase())
              }
              return selectCategoryData.find(it => it.value.toLowerCase() == child.category.toLowerCase())
            }
          )
          // .filter(
          //   (child) => {
          //     return selectSubCategoryData.find(it => it.value.toLowerCase() == child.asset.name.toLowerCase())?.selected
          //   }
          // )
          .filter(
            (child) =>
              !searchValue || child[name].toLowerCase().includes(searchValue.toLowerCase()),
          ).map((option) => (
            <Dropdown.Item eventKey={option[name]} key={option[name]}>
              {option[name]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
