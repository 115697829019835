import { MODALS_STATUS } from '../type/ModalsType'

const initialState = {
  statusSubscribeModal: false,
}

export default function ModalsReducer(state = initialState, action) {
  switch (action.type) {
    case MODALS_STATUS.SUBSCRIBE_MODAL:
      return { statusSubscribeModal: action.payload }
    default:
      return state
  }
}
