import React, { useContext, useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import { ThemeContext } from '../../../context/ThemeContext'
import ReactApexChart from 'react-apexcharts'
import {
  getDashboardCalendar,
  getProfile,
} from '../../../services/PostsService'
import { useDispatch, useSelector } from 'react-redux'
import { getSymbols } from '../../../store/selectors/SymbolsSelectors'
import { CardWithAuth } from '../common/CardWithAuth/CardWithAuth'
import { setLoading } from '../../../store/actions/LoadingActions'
import { setSubscribeModalStatus } from '../../../store/actions/ModalsActions'
import { useTranslation } from 'react-i18next'
import { checkProfile } from '../../../store/actions/PostActions'

const Home = () => {
  const [calendar, setCalendar] = useState([])
  const [seasonality, setSeasonality] = useState([])
  const [cot, setCot] = useState([])
  const [valuation, setValuation] = useState([])
  const { t, i18n } = useTranslation()

  const { changeBackground } = useContext(ThemeContext)
  const { activeSymbol, symbolsData } = useSelector(getSymbols)

  const dispatch = useDispatch()

  const openSubscribe = () => {
    dispatch(checkProfile())
  }

  useEffect(() => {
    activeSymbol &&
      getDashboardCalendar(activeSymbol?.id)
        .then(res => {
          setCalendar(res.data?.economicCalendar);
          setCot([
            {
              name: t('commitmentOfTraders.commercial'),
              data: res.data?.cot?.COMMERCIAL
            },{
              name: t('commitmentOfTraders.noReportables'),
              data: res.data?.cot?.NON_REPORTABLES
            }
          ]);
          setSeasonality(res.data?.seasonality);
          setValuation(res.data?.campusValuation);
        })
        .catch(error => console.error(error))
    changeBackground({ value: 'dark', label: 'Dark' })
  }, [activeSymbol?.id])
  const dataSeasonality = {
    series: [
      {
        name: '',
        data: seasonality,
      },
    ],
    options: {
      tooltip: {
        enabled: false,
      },
      grid: {
        show: false
      },
      colors: ['#75c3e7', '#75c3e7', '#75c3e7'],
      chart: {
        id: 'data-Seasonality',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      grid: {
        show: false
      },
      legend: {
        show: true,
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        }
      },
      yaxis: {
        show: false
      }
    },
  }
  const dataValuationLine = {
    series: [
      {
        data: valuation,
      },
    ],
    options: {
      colors: ['#75c3e7', '#75c3e7', '#75c3e7'],
      chart: {
        id: 'data-Seasonality',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      grid: {
        show: false
      },
      legend: {
        show: true,
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        }
      },
      yaxis: {
        show: false
      }
    },
  }
  const dataTraiderLine = {
    series: cot,
    options: {
      colors: ['#75c3e7', '#a52290', '#cbcf1f'],
      chart: {
        id: 'data-Seasonality',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      grid: {
        show: false
      },
      legend: {
        show: true,
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        }
      },
      yaxis: {
        show: false
      }
    },
  }

  return (
    <>
      <Row xs={1} md={2} className="g-4">
        <CardWithAuth
          title={t('menu.seasonality')}
          link={'/seasonality'}
          onClick={openSubscribe}
        >
          {dataSeasonality.series[0]?.data?.length ? (
            <ReactApexChart
              options={dataSeasonality.options}
              series={dataSeasonality.series}
              type="line"
              height={250}
            />
          ) : t('seasonality.noData')}
        </CardWithAuth>

        <CardWithAuth
          title={t('menu.economicCalendar')}
          link={'/economic-calendar'}
          onClick={openSubscribe}
        >
          <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12 ">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive recentOrderTable">
                  <table className="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Impact</th>
                        <th scope="col">Event</th>
                      </tr>
                    </thead>
                    <tbody>
                      {calendar.map((el, ind) => {
                        let date = new Date(el.date)
                          .toLocaleDateString()
                          .replace(/\//g, '.')
                        let dateTime = new Date(el.date)
                        let options = {
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true,
                        }
                        let time = dateTime.toLocaleString('en-US', options)
                        return (
                          <tr key={ind}>
                            <td>
                              {`
                                  ${date} 
                                  ${time}`}
                            </td>
                            <td>{el.impact}</td>
                            <td>{el.event}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </CardWithAuth>

        <CardWithAuth
          title={t('menu.cotFullName')}
          link={'/cot'}
          onClick={openSubscribe}
        >
          {(dataTraiderLine.series[0]?.data.length || dataTraiderLine.series[1]?.data.length || dataTraiderLine.series[2]?.data.length) ? (
          <ReactApexChart
            options={dataTraiderLine.options}
            series={dataTraiderLine.series}
            type="line"
            height={250}
          />)
            : t('cot.noData')}
        </CardWithAuth>

        <CardWithAuth
          title={activeSymbol?.id === 34 ? t('menu.campusValuationCompariosWithSNP500') : t('menu.campusValuationCompariosWithDollarIndex')}
          link={'/valuation'}
          onClick={openSubscribe}
        >
          <ReactApexChart
            options={dataValuationLine.options}
            series={dataValuationLine.series}
            type="line"
            height={250}
          />
        </CardWithAuth>
      </Row>
    </>
  )
}
export default Home
