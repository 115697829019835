import { SET_LOADING } from '../type/LoadingType'

const initialState = {
  loading: false,
}

export default function LoadingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return { loading: action.payload }
    default:
      return state
  }
}
