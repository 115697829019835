import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import './cardWithAuth.scss'
import { permissionsCheck } from '../../../../utils/permission/permission'
import { ROLES } from '../../../../types/role.Types'
import { t } from 'i18next'

export const CardWithAuth = ({
  onClick = () => {},
  isSubscribe = permissionsCheck('hasSubscription') || permissionsCheck('active'),
  link,
  title,
  children,
}) => {
  return (
    <Col>
      <Link to={isSubscribe && link}>
        <Card onClick={isSubscribe ? () => {} : onClick}>
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <div className="wrapper-body-card">
              <div className={isSubscribe ? '' : 'no-subscribe'}>
                {children}
              </div>
              {!isSubscribe && (
                <div className="no-subscribe-card">
                  <svg
                    width="96"
                    height="96"
                    fill="currentColor"
                    className="bi bi-lock"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
                  </svg>
                  <p>{t('subscribe.access')}</p>
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      </Link>
    </Col>
  )
}
