import React from 'react'

const Footer = () => {
  var d = new Date()
  return (
    <div className="footer out-footer">
      <div className="copyright d-flex justify-content-between">
        <p>Copyright © 2023 Edge Indicators</p>
      </div>
    </div>
  )
}

export default Footer
