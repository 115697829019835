import { SYMBOLS_TYPE } from '../type/SymbolsType'

const initialState = {
  activeSymbol: null,
  symbolsData: [],
}

export default function SymbolsReducer(state = initialState, action) {
  switch (action.type) {
    case SYMBOLS_TYPE.GET_ALL_SYMBOLS:
      return { activeSymbol: action.activeSymbol, symbolsData: action.data }
    case SYMBOLS_TYPE.SET_ACTIVE_SYMBOL:
      return { ...state, activeSymbol: action.activeSymbol }
    default:
      return state
  }
}
