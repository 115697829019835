import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const usePeriods = () => {
  const { t, i18n } = useTranslation()
  const [dataPeriods, setDataPeriods] = useState([
    {
      depth: 5,
      name: `5 ${t('periods.years')}`,
      time: new Date(
        new Date().setFullYear(new Date().getFullYear() - 5),
      ).toISOString(),
    },
    {
      depth: 10,
      name: `10 ${t('periods.years')}`,
      time: new Date(
        new Date().setFullYear(new Date().getFullYear() - 10),
      ).toISOString(),
    },
    {
      depth: 15,
      name: `15 ${t('periods.years')}`,
      time: new Date(
        new Date().setFullYear(new Date().getFullYear() - 15),
      ).toISOString(),
    },
    {
      depth: 20,
      name: `20 ${t('periods.years')}`,
      time: new Date(
        new Date().setFullYear(new Date().getFullYear() - 15),
      ).toISOString(),
    },
  ])

  return { dataPeriods, setDataPeriods }
}
