import {React} from 'react';
import {Link} from 'react-router-dom';

const Tooltip = (props) => {
    const {
        refTooltip,
        time,
        data
    } = props;

    return(
        <div ref={refTooltip} className='tooltip-chart'>
            <div className='title'>{time}</div>
            {data?.map(it => {
                return (
                    <div className='body'>{it.title}:{it.value}</div>
                )
            })}
        </div>
    )
}

export default Tooltip;