import { AUTH_ACTIONS } from '../type/AuthActionsTypes'

const initialState = {
  auth: {
    email: '',
    idToken: '',
    localId: '',
    expiresIn: '',
    refreshToken: '',
  },
  errorMessage: '',
  successMessage: '',
  showLoading: false,
}

export function AuthReducer(state = initialState, action) {
  if (action.type === AUTH_ACTIONS.SIGNUP_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: '',
      successMessage: 'Signup Successfully Completed',
      showLoading: false,
    }
  }
  if (action.type === AUTH_ACTIONS.LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: '',
      successMessage: 'Login Successfully Completed',
      showLoading: false,
    }
  }

  if (action.type === AUTH_ACTIONS.LOGOUT_ACTION) {
    return {
      ...state,
      errorMessage: '',
      successMessage: '',
      auth: {
        email: '',
        idToken: '',
        localId: '',
        expiresIn: '',
        refreshToken: '',
      },
    }
  }
  if (
    action.type === AUTH_ACTIONS.SIGNUP_FAILED_ACTION ||
    action.type === AUTH_ACTIONS.LOGIN_FAILED_ACTION
  ) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: '',
      showLoading: false,
    }
  }
  if (action.type === AUTH_ACTIONS.LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    }
  }

  return state
}
