import axiosInstance from '../services/AxiosInstance'

const recoveryPasswordReturnToUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_RECOVERY_PASSWORD_RETURN_URL_DEV
    : process.env.REACT_APP_RECOVERY_PASSWORD_RETURN_URL

export function getProfile() {
  return axiosInstance.get(`/v1/auth/profile`, { withCredentials: true })
}

export function updateProfile(updatedProfile) {
  return axiosInstance.put(`/v1/auth/profile`, updatedProfile).then(respons => {
    localStorage.setItem('profile', JSON.stringify(respons.data))
  })
}

export function updatePassword(updatedPassword) {
  return axiosInstance.put(`/v1/auth/password`, updatedPassword)
}

export function getSymbolsByCandleForMarketData(
  symbolId,
  candleSize,
  limit,
  timeFrom,
) {
  return axiosInstance.get(
    `v1/market-data/symbols/${symbolId}/candles?${
      candleSize ? `candleSize=` + candleSize : ''
    }${!!limit ? `&limit=${limit}` : ''}${
      !!timeFrom ? `&timeFrom=${timeFrom}` : ''
    }`,
  )
}

export function getSymbolsForMarketData() {
  return axiosInstance.get(`v1/market-data/symbols`)
}

export function getChartSeasonality(sympolId, depth) {
  return axiosInstance.get(
    `v1/chart/seasonality?symbolId=${sympolId}${
      depth ? `&depth=${depth}` : ''
    }`,
  )
}

export function getChartCampusValuation(data) {
  return axiosInstance.post(`v1/chart/campus-valuation`, data)
}

export function getChartCOT(sympolId, group) {
  return axiosInstance.get(
    `v1/chart/cot?symbolId=${sympolId}&cotGroup=${group}`,
  )
}

export function getDashboardCalendar(sympolId) {
  return axiosInstance.get(`v1/dashboard?symbolId=${sympolId}`)
}

export function getEconomicCalendar(dateFrom, dateTo) {
  return axiosInstance.get(
    `v1/economic-calendar?dateFrom=${dateFrom}&dateTo=${dateTo}`,
  )
}

export function recoveryPasswordRequest(recoveryRequestData) {
  const data = {
    ...recoveryRequestData,
    returnTo: recoveryPasswordReturnToUrl,
  }
  return axiosInstance.post(`/v1/auth/recovery-request`, data)
}

export function recoveryPasswordConfirm(recoveryConfirmData) {
  return axiosInstance.post(`/v1/auth/recovery-confirm`, recoveryConfirmData)
}

export function getPosts() {
  return axiosInstance.get(`posts.json`)
}

export function createPost(postData) {
  return axiosInstance.post(`posts.json`, postData)
}

export function updatePost(post, postId) {
  return axiosInstance.put(`posts/${postId}.json`, post)
}

export function deletePost(postId) {
  return axiosInstance.delete(`posts/${postId}.json`)
}

export function formatPosts(postsData) {
  let posts = []
  for (let key in postsData) {
    posts.push({ ...postsData[key], id: key })
  }

  return posts
}
