import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import logo from '../../images/logo/logo-full.png'
import bg6 from '../../images/background/bg6.png'
import Swal from 'sweetalert2'
import { recoveryPasswordRequest } from '../../services/PostsService'

function ForgotPassword(props) {
  const [heartActive, setHeartActive] = useState(true)
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const email = 'location.state.email'
  const navigate = useNavigate()
  const [verificationCode, setVerificationCode] = useState('')
  let errorsObj = { verificationCode: '', password: '' }
  const [errors, setErrors] = useState(errorsObj)
  const dispatch = useDispatch()

  function onVerivied(e) {
    e.preventDefault()
    recoveryPasswordRequest({
      identifierType: 'EMAIL',
      identifier: verificationCode,
    })
      .then(() => {
        Swal.fire({
          title: t('profile.forget-password-description'),
          icon: 'success',
          confirmButtonText: t('profile.goIt'),
        })
      })
      .catch((error) => {
        console.error(error.response.status)
        let errorCode = error.response.status.toString()
        Swal.fire({
          title: t(`ui.errors.error-boundary.${errorCode}.message`),
          icon: 'error',
          confirmButtonText: t(`profile.goIt`),
        })
      })
  }

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{ background: '#fff url(' + bg6 + ')', height: '100vh', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
        >
          <div className="row gx-0">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
              <div
                id="mCSB_1"
                className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                style={{ maxHeight: '653px' }}
              >
                <div
                  id="mCSB_1_container"
                  className="mCSB_container"
                  style={{
                    position: 'relative',
                    top: '0',
                    left: '0',
                    dir: 'ltr',
                  }}
                >
                  <div className="login-form style-2">
                    <div className="card-body">
                      <div className="logo-header">
                        <Link to={'/login'} className="logo">
                          <img
                            src={logo}
                            alt=""
                            className="width-230 mCS_img_loaded"
                          />
                        </Link>
                      </div>
                      <div className="nav nav-tabs border-bottom-0">
                        <div className="tab-content w-100" id="nav-tabContent">
                          <div
                            className="tab-pane fade active show"
                            id="nav-personal"
                          >
                            {props.errorMessage && (
                              <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                                {props.errorMessage}
                              </div>
                            )}
                            {props.successMessage && (
                              <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                                {props.successMessage}
                              </div>
                            )}
                            <form
                              className=" dz-form pb-3"
                              onSubmit={onVerivied}
                            >
                              <h3 className="form-title m-t0">
                                {t('fogot-password.title')}
                              </h3>
                              <div className="dz-separator-outer m-b5">
                                <div className="dz-separator bg-primary style-liner"></div>
                              </div>
                              <p>{t('fogot-password.description')}</p>
                              <div className="form-group mb-3">
                                {/* <input name="dzName" required="" className="form-control" placeholder="User Name" type="text" /> */}
                                <input
                                  className="form-control"
                                  placeholder={t('fogot-password.email')}
                                  value={verificationCode}
                                  onChange={e =>
                                    setVerificationCode(e.target.value)
                                  }
                                />
                                {errors.email && (
                                  <div className="text-danger fs-12">
                                    {errors.email}
                                  </div>
                                )}
                              </div>
                              <div className="form-group clearfix text-left mb-3 mt-3">
                                <NavLink
                                  to="/login"
                                  className="btn btn-primary outline gray"
                                  type="button"
                                >
                                  {t('verification.goToLogin')}
                                </NavLink>
                                <button
                                  type="submit"
                                  className="btn btn-primary float-end"
                                >
                                  {t('fogot-password.send')}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
