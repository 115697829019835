import { SYMBOLS_TYPE } from '../type/SymbolsType'
import { getSymbolsForMarketData } from '../../services/PostsService'
import { setLoading } from './LoadingActions'

export function setSymbols(data) {
  return {
    type: SYMBOLS_TYPE.GET_ALL_SYMBOLS,
    data: data,
    activeSymbol: data.find(it => it.id == 32) ?? data[0],
  }
}

export function setActiveSymbols(el) {
  return {
    type: SYMBOLS_TYPE.SET_ACTIVE_SYMBOL,
    activeSymbol: el,
  }
}

export function getAllSymbols() {
  return dispatch => {
    dispatch(setLoading(true))
    getSymbolsForMarketData()
      .then(res => {
        dispatch(setSymbols(res.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        console.error(error)
        dispatch(setLoading(false))
      })
  }
}
