import React, { useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'

const DashboardComboChart = ({ graphData, name, currentDay }) => {
  
  const state = {
    series: [
      {
        name: name,
        data: graphData,
      },
    ],
    options: {
      annotations: {
        xaxis: [
          {
            x: currentDay && new Date().getTime(),
            borderColor: '#75c3e7',
            label: {
              borderColor: '#75c3e7',
              orientation: 'horizontal',
              text: new Date().toLocaleDateString('en-US', {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              }),
              style: {
                color: '#fff',
                background: '#75c3e7',
              },
            },
          },
        ],
      },
      colors: ['#75c3e7', '#75c3e7', '#75c3e7'],
      chart: {
        type: 'area',
        stacked: false,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
        toolbar: {
          autoSelected: 'zoom',
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: graphData.length
          ? ''
          : `Sorry, the chart data is not available. This tool is not supported for seasonality indicator`,
        align: 'left',
      },
      fill: {
        type: 'gradient',
        colors: ['#75c3e7'],
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100],
        },
      },
      yaxis: {
        title: {
          text: 'Relative asset price ',
        },
        labels: {
          show: false,
        },
        // labels: {
        //   formatter: function (val) {
        //     let formatNumber = Math.round(val * 100) / 100
        //     return formatNumber + ' %'
        //   },
        // },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'MMM',
        },
        // labels: {
        //   formatter: function (val) {
        //     return val + ' %'
        //   },
        // },
        // title: {
        //   text: 'Month',
        // },
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val) {
            return val + '%'
          },
        },
      },
    },
  }

  return (
    <div className="chart-box">
      <div id="chart-candlestick">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="area"
          height={350}
        />
      </div>
    </div>
  )
}

export default DashboardComboChart
