import React from 'react'
import { useSelector } from 'react-redux'
import './loader.scss'
import { getIsLoading } from '../../../../store/selectors/LoadingSelectors'

export const Loader = () => {
  const isLoad = useSelector(getIsLoading)

  return isLoad ? (
    <div className="loader">
      <div
        className="spinner-border text-success"
        style={{ width: '45px', height: '45px' }}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  ) : null
}
