import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { recoveryPasswordConfirm } from '../../services/PostsService'
import Swal from 'sweetalert2'
import { setLoading } from '../../store/actions/LoadingActions'

function ConfirmForgotPassword(props) {
  const [heartActive, setHeartActive] = useState(true)
  const { t, i18n } = useTranslation()
  const location = useLocation()
  const email = 'location.state.email'
  const navigate = useNavigate()
  const [verificationCode, setVerificationCode] = useState('')
  let errorsObj = { verificationCode: '', password: '' }
  const [errors, setErrors] = useState(errorsObj)
  const dispatch = useDispatch()
  let [token] = useSearchParams()

  function onVerivied(e) {
    e.preventDefault()
    dispatch(setLoading(true))
    recoveryPasswordConfirm({
      token: token.get('token'),
      password: verificationCode,
    })
      .then(res => {
        dispatch(setLoading(false))
        Swal.fire({
          title: t('confirm-fogot-password.confirm'),
          icon: 'success',
          confirmButtonText: t('profile.goIt'),
        }).then(() => {
          navigate('/app-profile')
        })
      })
      .catch(error => {
        dispatch(setLoading(false))
        console.error(error.response.status)
        let errorCode = error.response.status.toString()
        Swal.fire({
          title: t(`ui.errors.error-boundary.${errorCode}.message`),
          icon: 'error',
          confirmButtonText: t(`profile.goIt`),
        })
      })
  }

  return (
    <div className="page-wraper">
      <div className="row gx-0">
        <div className="col-xl-6 col-lg-5 col-md-6 col-sm-12 vh-100 bg-white ">
          <div className="login-form style-2">
            <div className="card-body">
              <div className="nav nav-tabs border-bottom-0">
                <div className="tab-content w-100" id="nav-tabContent">
                  <div className="tab-pane fade active show" id="nav-personal">
                    {props.errorMessage && (
                      <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                        {props.errorMessage}
                      </div>
                    )}
                    {props.successMessage && (
                      <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                        {props.successMessage}
                      </div>
                    )}
                    <form className=" dz-form pb-3" onSubmit={onVerivied}>
                      <h3 className="form-title m-t0">
                        {t('confirm-fogot-password.title')}
                      </h3>
                      <div className="dz-separator-outer m-b5">
                        <div className="dz-separator bg-primary style-liner"></div>
                      </div>
                      <p>{t('confirm-fogot-password.description')}</p>
                      <div className="form-group mb-3">
                        <input
                          className="form-control"
                          type="password"
                          placeholder={t('confirm-fogot-password.password')}
                          value={verificationCode}
                          onChange={e => setVerificationCode(e.target.value)}
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}
                      </div>
                      <div className="form-group clearfix text-left mb-3 mt-3">
                        <NavLink
                          to="/login"
                          className="btn btn-primary outline gray"
                          type="button"
                        >
                          {t('verification.goToLogin')}
                        </NavLink>
                        <button
                          type="submit"
                          className="btn btn-primary float-end"
                        >
                          {t('confirm-fogot-password.send')}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmForgotPassword
