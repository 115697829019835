/// Menu
import React, {
  Component,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
//import Metismenu from "metismenujs";
/// Scroll
import PerfectScrollbar from 'react-perfect-scrollbar'
import Collapse from 'react-bootstrap/Collapse'
import Button from 'react-bootstrap/Button'

/// Link
import { Link, NavLink } from 'react-router-dom'

import { MenuList } from './Menu'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { ThemeContext } from '../../../context/ThemeContext'
import { permissionsCheck } from '../../../utils/permission/permission'
import { useTranslation } from 'react-i18next'
import { ROLES } from '../../../types/role.Types'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../../store/actions/LoadingActions'
import { getProfile } from '../../../services/PostsService'
import { setSubscribeModalStatus } from '../../../store/actions/ModalsActions'
import { checkProfile } from '../../../store/actions/PostActions'

//import medal from "../../../images/medal.png";

// class MM extends Component {
// 	componentDidMount() {
// 		this.$el = this.el;
// 		this.mm = new Metismenu(this.$el);
// 	}
//   componentWillUnmount() {
//   }
//   render() {
//     return (
//       <div className="mm-wrapper">
//         <ul className="metismenu " ref={(el) => (this.el = el)}>
//           {this.props.children}
//         </ul>
//       </div>
//     );
//   }
// }

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
})

const initialState = {
  active: '',
  activeSubmenu: '',
}

const SideBar = () => {
  const [hideOnScroll, setHideOnScroll] = useState(true)
  const [state, setState] = useReducer(reducer, initialState)
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const profile = JSON.parse(localStorage.getItem('profile'))
  //For scroll
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll],
  )

  const handleMenuActive = status => {
    setState({ active: status })

    if (state.active === status) {
      setState({ active: '' })
    }
  }

  const handleSubmenuActive = status => {
    setState({ activeSubmenu: status })
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: '' })
    }
  }

  /// Path
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]

  const openSubscribe = () => {
    dispatch(checkProfile())
  }

  return (
    <div
      className={`deznav  border-right ${iconHover} ${
        sidebarposition.value === 'fixed' &&
        sidebarLayout.value === 'horizontal' &&
        headerposition.value === 'static'
          ? hideOnScroll > 120
            ? 'fixed'
            : ''
          : ''
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            let menuClass = data.classsChange
            let isSubscribeLink = profile.active || profile.hasSubscription ||
              data.title === 'dashboard'

            if (data.hidden && data.permission.length && !permissionsCheck('active') && !permissionsCheck('hasSubscription')) {
              return;
            }
            if (menuClass === 'menu-title') {
              return (
                <li className={menuClass} key={index}>
                  {t(`menu.${data.title}`)}
                </li>
              )
            } else {
              return (
                <li
                  className={` ${
                    state.active === data.title ? 'mm-active' : ''
                  }`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ? (
                    <Link
                      to={'#'}
                      className="has-arrow"
                      onClick={e => {
                        handleMenuActive(data.title)
                      }}
                    >
                      {data.iconStyle}
                      <span className="nav-text">
                        {t(`menu.${data.title}`)}
                      </span>
                    </Link>
                  ) : (
                    <NavLink
                      to={
                        (permissionsCheck('hasSubscription') || permissionsCheck('active') ||
                          data.title === 'dashboard') &&
                        data.to
                      }
                      className={`${path === data.to ? 'mm-active' : ''} ${data.isMobile ? 'd-none d-mobile-block' : ''}`}
                      onClick={() => {
                        !isSubscribeLink && openSubscribe()
                      }}
                    >
                      {isSubscribeLink ? (
                        data.iconStyle
                      ) : (
                        <i className="material-icons"> lock </i>
                      )}
                      <span className="nav-text">
                        {t(`menu.${data.title}`)}
                      </span>
                    </NavLink>
                  )}
                </li>
              )
            }
          })}
        </ul>
      </PerfectScrollbar>
    </div>
  )
}

export default SideBar
