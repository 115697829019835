import React, { useState, useEffect } from 'react'
import { Row, Col, Card, Table, Badge } from 'react-bootstrap'
import { getEconomicCalendar } from '../../services/PostsService'
import { useDispatch } from 'react-redux'
import { setLoading } from '../../store/actions/LoadingActions'

export const EconomicCalendar = () => {
  const [data, setData] = useState([])
  const dispatch = useDispatch()

  const getDateFrom = () => {
    let date = new Date()
    date.setHours(0, 0, 0, 0)
    let offset = date.getTimezoneOffset() * 60 * 1000
    date = new Date(date - offset)
    let isoString = date.toISOString()
    return isoString
  }

  const getDateTo = () => {
    let date = new Date()
    date.setDate(date.getDate() + 1)
    date.setHours(23, 59, 59, 999)
    let offset = date.getTimezoneOffset() * 60 * 1000
    date = new Date(date - offset)
    let isoString = date.toISOString()
    return isoString
  }

  useEffect(() => {
    dispatch(setLoading(true))
    getEconomicCalendar(getDateFrom(), getDateTo())
      .then(res => {
        setData(res.data)
        dispatch(setLoading(false))
      })
      .catch(err => {
        console.error(err)
        dispatch(setLoading(false))
      })
  }, [])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Economic Calendar</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>Country</th>
                  <th>Currency</th>
                  <th>Event</th>
                  <th>Impact </th>
                  <th>Previous </th>
                  <th>Actual </th>
                </tr>
              </thead>
              <tbody>
                {data.map((el, ind) => {
                  let date = new Date(el.date)
                    .toLocaleDateString()
                    .replace(/\//g, '.')
                  let dateTime = new Date(el.date)
                  let options = {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  }
                  let time = dateTime.toLocaleString('en-US', options)

                  const checkImpactClass = impact => {
                    switch (impact) {
                      case 'High':
                        return 'badge-info'
                      case 'Medium':
                        return 'badge-success'
                      default:
                        return 'badge-dark'
                    }
                  }

                  const isNewDay =
                    ind !== 0
                      ? new Date(data[ind - 1]?.date).getDate() !==
                        new Date(el.date).getDate()
                      : false

                  return (
                    <>
                      {isNewDay && (
                        <tr
                          className="badge-success text-center"
                          key={`day-key-${ind}`}
                        >
                          <td key={ind + 'day-collapse'} colSpan={8}>
                            {new Date(el.date).toLocaleDateString('en-US', {
                              weekday: 'long',
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric',
                            })}
                          </td>
                        </tr>
                      )}
                      <tr key={ind}>
                        <th key={ind + 0}>{ind + 1}</th>
                        <td key={ind + 1}>{`${date} ${time}`}</td>
                        <td key={ind + 2}>{el?.country}</td>
                        <td key={ind + 3}>{el?.currency}</td>
                        <td key={ind + 4}>{el?.event}</td>
                        <td key={ind + 5}>
                          <Badge
                            bg=""
                            className={`${checkImpactClass(el?.impact)} `}
                          >
                            {el?.impact}
                          </Badge>
                        </td>
                        <td key={ind + 6}>{el.previous}</td>
                        <td key={ind + 7} className="color-primary">
                          {el.actual}
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
